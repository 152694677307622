//FIXME: Gambiarra made to differentiate establishments from restaurants. It is to be removed in the future for a better alternative.

//Array of establishments that are NOT restaurants

export const venuesListSpecial = [
	'56c776ff0896b3cd13c6012b', //  <--  Venue default para restaurante, mudar quando for usar em NÃO restaurante
	'6125447b75247f002b6b2d77',
	'611bea47b6e5570011d3ecdf',
	'611bddd561e55300119e6b03',
	'6179572a0b98eb00118ad85e',
	'617957390a685c001189e2ef',
	'6179571dd00e4d00113d300a',
	'61794c3a17a41200117fb313',
	'617953f679a37a00117da2ef',
	'6176eb551f15c400208bb49a',
	'617961a40b98eb00118ad932',
	'6179540030192500113cd2c3',
	'617fe29c524cc2001260d71a',
	'617fe2b2524cc2001260d71d',
	'6177131d4ad69e00113ec760',
	'617fe20dfc970d0012a85b96',
	'61b23f6038ad3300377ba609',
	'61d87f6bd48b32001240ee70',
	'5d9368280a4ababa6d5f1768',
	'5f318fa48b616a24ea609485',
	'5f3190292c91f2253563542d',
	'62bc71a19e315d0011acdd6b',
	'62c34044c5cd6d0020830beb',
	'627a6104c42ce40020ce813b',
	'62828a3b2f3fce00119054ce',
	'62b0cabd275a630011aa3212',
	'62965f88b5eb200011d1023f',
	'63d2d6ba8b3f420052b5d9b2',
	'63d2d6d110425100528963ca',
	'63f7bdc0eaa85300521d1ddf',
	'6176e150f9e1020011e3ab90',
	'6179619a79a37a00117da3fc',
	'64b83eb72987cd00505742d9',
	'65bd07b24b869e006c67f600' //Nosso Camarote
]

export const brManiaList = [
	'56c776ff0896b3cd13c6012b',
	'67af4e769b7f744541782ca4',
	'67af4e839b7f744541782e3c',
	'67af4e969b7f744541782f58',
	'67b32d349b7f744541ee6e28'
]